<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" fullscreen @open="loadData">
    <div
      v-loading="loading"
      id="user-relational-chart"
      :style="{ height: chartHeight }"
    ></div>
  </el-dialog>
</template>

<script>
import eCharts from "echarts";
import { getInvitationRelationalTree } from "@/api/userManage";
export default {
  data() {
    return {
      treeLen: 0,
      loading: true
    };
  },
  computed: {
    chartHeight() {
      const { treeLen } = this;
      if (!treeLen) {
        return "85vh";
      }
      return Math.ceil(treeLen / 20) * 85 + "vh";
    }
  },
  methods: {
    handleData(tree) {
      return tree.map(item => {
        if (item.children?.length) {
          item.name = `${item.name}(${item.children.length})`;
          this.handleData(item.children);
        }
        return item;
      });
    },
    async loadData() {
      this.loading = true;
      const res = await getInvitationRelationalTree();
      setTimeout(() => {
        this.loading = false;
      }, 500);
      if (res) {
        this.treeLen = res.length;
        this.$nextTick(() => {
          this.initChart({ name: "房猫PCN", children: this.handleData(res) });
        });
      }
    },
    async initChart(data) {
      const myChart = eCharts.init(
        document.getElementById("user-relational-chart")
      );
      data.children.forEach(function(datum, index) {
        index % 2 === 0 && (datum.collapsed = true);
      });
      myChart.setOption({
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove"
        },
        series: [
          {
            type: "tree",
            data: [data],
            top: "1%",
            left: "7%",
            bottom: "1%",
            right: "20%",
            symbolSize: 16,
            label: {
              position: "left",
              verticalAlign: "middle",
              align: "right",
              fontSize: 12
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left"
              }
            },
            emphasis: {
              focus: "descendant"
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      });
    }
  }
};
</script>

<style scoped lang="scss">
#user-relational-chart {
  width: 90vw;
  height: 200vh;
}
</style>

<template>
  <div class="base-container">
    <div class="header-mol">
      <div class="info">
        <div class="title">注册用户</div>
        <div class="summary">
          <template v-if="$platSs(2)"
            ><strong>渠道经纪人</strong
            >，需要绑定合作机构，机构“管理员”角色负责全面管理机构相关业务；<strong>新房置业顾问</strong>，即无须绑定任何机构，独立开展业务；</template
          ><strong>平台员工</strong
          >，协同用户认证，负责楼盘项目营销运营，比如报备专员、项目经理、置业顾问，申请需要人工审核；
        </div>
      </div>
    </div>
    <div class="mt20 filter-bar">
      <div>
        <el-input
          placeholder="用户姓名、手机号"
          v-model="queryData.keyword"
          style="width: 320px;"
          class="f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          v-if="$platSs(2)"
          v-model="queryData.distributionType"
          placeholder="用户类型"
          class="ml8"
          filterable
          clearable
          @change="handleDistrTypeChange"
        >
          <el-option
            :label="label"
            :value="value"
            v-for="(label, value) in distributionTypeMaps"
            :key="value"
          >
          </el-option>
        </el-select>
        <el-select
          v-if="$platSs(2)"
          v-model="queryData.organId"
          placeholder="自有渠道"
          class="ml8"
          filterable
          clearable
          @change="handleOrganChange"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in mechanismList"
            :key="item.id"
          >
          </el-option>
        </el-select>
        <el-date-picker
          style="width: 240px;margin:0 8px;"
          v-model="queryDate"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="handleDateChange"
        >
        </el-date-picker>
        <!-- 房猫PCN -->
        <el-checkbox
          v-if="$platformInfo.id == 34"
          style="padding: 10px;"
          v-model="sceneStr"
          >元气里扫码注册用户(导出表格生效)</el-checkbox
        >
        <el-popover
          placement="bottom"
          width="200"
          v-if="$platformInfo.terminalList?.includes(1)"
          trigger="hover"
          @show="getAppQrcode"
        >
          <div class="qrcode-box y aic">
            <el-image :src="appQrcode" class="AppQrcode"></el-image>
            <p class="x1 color9">请使用微信扫描二维码</p>
          </div>
          <el-button type="text" primary class="qrcode" slot="reference">
            邀请员工注册
          </el-button>
        </el-popover>
      </div>
      <div style="white-space: nowrap">
        <el-button
          v-if="$platSs(2)"
          type="primary"
          @click="relationalChartVisible = true"
          >邀请关系图</el-button
        >
        <el-button type="primary" @click="exportExcel">导出表格</el-button>
      </div>
    </div>
    <el-table
      class="body mt20"
      border
      :data="list"
      :row-class-name="tableRowClassName"
    >
      <el-table-column prop="realName" label="注册用户">
        <template v-slot="{ row }">
          <div class="user-info">
            <el-image :src="row.avatarPath"></el-image>
            <p>{{ row.realName }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="cellphone" label="联系电话">
        <template v-slot="{ row }">
          {{ row.cellphone | formatPhone }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="邀请人">
        <template v-slot="{ row }">
          {{ row.invitationUserName || "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="organName"
        label="自有渠道"
        width="200"
        v-if="$platSs(2)"
      >
        <template v-slot="{ row }">
          {{ row.organName || "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="distributionType"
        label="用户类型"
        v-if="$platSs(2)"
      >
        <template v-slot="{ row }">
          {{ distributionTypeMaps[row.distributionType] || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="roleType" label="机构角色">
        <template v-slot="{ row }">
          <span v-if="row.roleType" :class="{ red: row.roleType == 2 }">
            {{ row.roleType == 1 ? "经纪人" : "管理员" }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="inDate" label="注册时间"> </el-table-column>
      <el-table-column prop="auditStatus" label="状态">
        <template v-slot="{ row }">
          <span v-if="row.auditStatus == 1">
            {{ statusMaps[row.status] }}
          </span>
          <span :class="auditStatusMaps[row.auditStatus]?.color" v-else>{{
            auditStatusMaps[row.auditStatus]?.label || "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template v-slot="{ row }">
          <el-button
            type="text"
            v-if="
              row.auditStatus == 1 &&
                row.status == 1 &&
                row.distributionType != 2
            "
            @click="admin(row)"
            >{{ row.roleType == 2 ? "取消管理员" : "设为管理员" }}</el-button
          >
          <el-button type="text" v-if="row.auditStatus == 1" @click="lock(row)">
            {{ row.status == 1 ? "锁定" : "恢复" }}
          </el-button>
          <el-button
            type="text"
            v-if="row.auditStatus == 0"
            @click="audit(row.id, 2)"
          >
            审核
          </el-button>
          <el-button
            type="text"
            @click="audit(row.id, 1)"
            v-if="row.auditStatus == 2"
          >
            通过
          </el-button>
          <el-button type="text" @click="del(row)">
            <span class="red">删除</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getList"
    ></f-pagination>
    <relational-chart
      title="邀请关系图"
      :visible.sync="relationalChartVisible"
    ></relational-chart>
  </div>
</template>

<script>
import {
  customerList,
  setAdmin,
  auditCustomer,
  deleteCustomer,
  lockCustomer,
  mechanismListAll,
  exportUserExcel
} from "@/api/userManage";
import { getAppQrcode } from "@/api/platform";
import { BASE_URL } from "@/config";
import qs from "qs";
import RelationalChart from "./_components/relationalChart";

export default {
  components: {
    RelationalChart
  },
  data() {
    return {
      appQrcode: "", //邀请注册平台员工二维码
      total: 0,
      queryData: {
        keyword: "",
        organId: "",
        distributionType: "", //用户类型
        pageSize: 10,
        pageNum: 1,
        dateStart: "",
        dateEnd: ""
      },
      relationalChartVisible: false,
      sceneStr: false,
      queryDate: [],
      mechanismList: [], // 机构列表
      list: [],
      distributionTypeMaps: {
        1: "渠道经纪人",
        2: "新房置业顾问",
        3: "平台员工"
      }, // 1 渠道经纪人 2 新房置业顾问 3 员工
      auditStatusMaps: {
        0: {
          color: "orange",
          label: "待审"
        },
        1: {
          color: "orange",
          label: "通过"
        },
        2: {
          color: "red",
          label: "不通过"
        },
        10: {
          color: "orange",
          label: "锁定"
        }
      }, //审核状态 0审核中 1通过 2不通过
      statusMaps: {
        0: "锁定",
        1: "正常"
      } //1正常 0锁定
    };
  },
  created() {
    this.getList();
    this.getMechanismList();
  },
  methods: {
    // 获取平台小程序二维码
    async getAppQrcode(row) {
      if (!this.appQrcode) {
        const res = await getAppQrcode({
          id: this.$platformInfo.id,
          scene: `regType#3`
        });
        if (res) {
          this.appQrcode = res;
        }
      }
    },
    exportExcel() {
      const { userId, token } = this.$userInfo;
      const { platformId } = this.$store.state.platformInfo;
      window.location.href = `${BASE_URL}/api/v1/platform/distribution/web/customer/export?${qs.stringify(
        {
          ...this.queryData,
          sceneStr: this.sceneStr ? 6515 : "",
          userId,
          token,
          platformId
        }
      )}`;
    },
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    handleDateChange(date) {
      const [dateStart, dateEnd] = date || [];
      Object.assign(this.queryData, {
        dateStart,
        dateEnd
      });
      this.search();
    },
    // 选择机构类型
    handleOrganChange() {
      // 选择机构的时候 不是渠道经纪人就清空用户类型选择
      if (this.queryData.distributionType != 1) {
        this.queryData.distributionType = "";
      }
      this.search();
    },
    // 选择分销用户类型
    handleDistrTypeChange(val) {
      // 不是渠道经纪人清空机构选择
      if (val != 1) {
        this.queryData.organId = "";
      }
      this.search();
    },
    // 机构列表
    async getMechanismList() {
      const res = await mechanismListAll();
      if (res) {
        this.mechanismList = res;
      }
    },
    // 注册用户列表
    async getList() {
      const res = await customerList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    // 给行添加className
    tableRowClassName({ row }) {
      if ((row.auditStatus == 1 && row.status == 0) || row.auditStatus == 2) {
        // 锁定状态
        return "disabled";
      }
    },
    // 设置or取消管理员
    async admin(row) {
      const res = await setAdmin({
        customerId: row.id,
        admin: row.roleType == 2 ? false : true
      });
      if (res) {
        console.log(res);
        this.getList();
      }
    },
    // 锁定
    async lock(row) {
      const res = await lockCustomer({
        customerId: row.id,
        lock: row.status == 1 ? true : false
      });
      if (res) {
        this.getList();
      }
    },
    // 审核
    audit(id, type) {
      const fn = async (status, remark) => {
        console.log(id, status, remark);
        const res = await auditCustomer({
          customerId: id,
          status, // 审核状态 0审核中 1通过 2不通过
          remark: status == 2 ? remark : "" // 不通过理由
        });
        if (res) {
          this.getList();
          this.$showSuccess("提交成功");
        }
      };
      if (type == 1) {
        fn(1); // 通过
      } else {
        this.$confirm("审核通过后，该注册用户将正式入驻当前分销平台", "审核", {
          customClass: "examine-form",
          confirmButtonText: "通过",
          cancelButtonText: "不通过",
          center: true,
          showClose: false,
          distinguishCancelAndClose: true
        })
          .then(() => {
            fn(1); // 审核 -> 通过
          })
          .catch(action => {
            if (action == "cancel") {
              // 不通过审核，输入不通过理由
              this.$prompt("", "不通过审核", {
                confirmButtonText: "提交",
                inputValidator(val) {
                  return !(!val || !val.trim());
                },
                inputErrorMessage: "理由不能为空",
                inputPlaceholder: "请输入不通过理由"
              })
                .then(({ value }) => {
                  // 审核 -> 不通过
                  fn(2, value);
                })
                .catch(() => {});
            }
          });
      }
    },
    // 删除
    del(row) {
      this.$confirm("删除后将无法恢复，确定要删除该用户吗？", "删除报备", {
        customClass: "examine-form",
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        center: true,
        showClose: false,
        confirmButtonClass: "delete"
      })
        .then(async () => {
          const res = await deleteCustomer({
            customerId: row.id
          });
          if (res) {
            this.$showSuccess("删除成功");
            this.search();
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="scss">
.filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.examine-form {
  padding-bottom: 0;
  width: 280px;
  .el-message-box__message {
    padding-bottom: 10px;
    p {
      color: #aaa;
      text-align: left;
      line-height: 150%;
      font-size: 13px;
    }
  }
  .el-message-box__btns {
    display: flex;
    border-top: 1px solid #eee;
    padding-bottom: 5px;
    .el-button {
      flex: 1;
      border: none;
      background: none;
      font-size: 16px;
    }
    .el-button--primary {
      color: $primary;
    }
    .delete {
      color: red;
    }
  }
}
</style>

<style lang="scss" scoped>
.orange {
  color: $warning;
}
::v-deep .el-table .disabled {
  color: #aaa;
  .user-info {
    .el-image {
      opacity: 0.5;
    }
    p {
      color: #aaa;
    }
  }
}
.user-info {
  display: flex;
  align-items: center;
  .el-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "fullscreen": ""
    },
    on: {
      "open": _vm.loadData
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    style: {
      height: _vm.chartHeight
    },
    attrs: {
      "id": "user-relational-chart"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
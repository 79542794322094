var render = function render(){
  var _vm$$platformInfo$ter;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-container"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("注册用户")]), _c('div', {
    staticClass: "summary"
  }, [_vm.$platSs(2) ? [_c('strong', [_vm._v("渠道经纪人")]), _vm._v("，需要绑定合作机构，机构“管理员”角色负责全面管理机构相关业务；"), _c('strong', [_vm._v("新房置业顾问")]), _vm._v("，即无须绑定任何机构，独立开展业务；")] : _vm._e(), _c('strong', [_vm._v("平台员工")]), _vm._v("，协同用户认证，负责楼盘项目营销运营，比如报备专员、项目经理、置业顾问，申请需要人工审核； ")], 2)])]), _c('div', {
    staticClass: "mt20 filter-bar"
  }, [_c('div', [_c('el-input', {
    staticClass: "f-search-bar",
    staticStyle: {
      "width": "320px"
    },
    attrs: {
      "placeholder": "用户姓名、手机号"
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _vm.$platSs(2) ? _c('el-select', {
    staticClass: "ml8",
    attrs: {
      "placeholder": "用户类型",
      "filterable": "",
      "clearable": ""
    },
    on: {
      "change": _vm.handleDistrTypeChange
    },
    model: {
      value: _vm.queryData.distributionType,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "distributionType", $$v);
      },
      expression: "queryData.distributionType"
    }
  }, _vm._l(_vm.distributionTypeMaps, function (label, value) {
    return _c('el-option', {
      key: value,
      attrs: {
        "label": label,
        "value": value
      }
    });
  }), 1) : _vm._e(), _vm.$platSs(2) ? _c('el-select', {
    staticClass: "ml8",
    attrs: {
      "placeholder": "自有渠道",
      "filterable": "",
      "clearable": ""
    },
    on: {
      "change": _vm.handleOrganChange
    },
    model: {
      value: _vm.queryData.organId,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "organId", $$v);
      },
      expression: "queryData.organId"
    }
  }, _vm._l(_vm.mechanismList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1) : _vm._e(), _c('el-date-picker', {
    staticStyle: {
      "width": "240px",
      "margin": "0 8px"
    },
    attrs: {
      "type": "daterange",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      "change": _vm.handleDateChange
    },
    model: {
      value: _vm.queryDate,
      callback: function callback($$v) {
        _vm.queryDate = $$v;
      },
      expression: "queryDate"
    }
  }), _vm.$platformInfo.id == 34 ? _c('el-checkbox', {
    staticStyle: {
      "padding": "10px"
    },
    model: {
      value: _vm.sceneStr,
      callback: function callback($$v) {
        _vm.sceneStr = $$v;
      },
      expression: "sceneStr"
    }
  }, [_vm._v("元气里扫码注册用户(导出表格生效)")]) : _vm._e(), (_vm$$platformInfo$ter = _vm.$platformInfo.terminalList) !== null && _vm$$platformInfo$ter !== void 0 && _vm$$platformInfo$ter.includes(1) ? _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "width": "200",
      "trigger": "hover"
    },
    on: {
      "show": _vm.getAppQrcode
    }
  }, [_c('div', {
    staticClass: "qrcode-box y aic"
  }, [_c('el-image', {
    staticClass: "AppQrcode",
    attrs: {
      "src": _vm.appQrcode
    }
  }), _c('p', {
    staticClass: "x1 color9"
  }, [_vm._v("请使用微信扫描二维码")])], 1), _c('el-button', {
    staticClass: "qrcode",
    attrs: {
      "slot": "reference",
      "type": "text",
      "primary": ""
    },
    slot: "reference"
  }, [_vm._v(" 邀请员工注册 ")])], 1) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm.$platSs(2) ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.relationalChartVisible = true;
      }
    }
  }, [_vm._v("邀请关系图")]) : _vm._e(), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.exportExcel
    }
  }, [_vm._v("导出表格")])], 1)]), _c('el-table', {
    staticClass: "body mt20",
    attrs: {
      "border": "",
      "data": _vm.list,
      "row-class-name": _vm.tableRowClassName
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "realName",
      "label": "注册用户"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('div', {
          staticClass: "user-info"
        }, [_c('el-image', {
          attrs: {
            "src": row.avatarPath
          }
        }), _c('p', [_vm._v(_vm._s(row.realName))])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "cellphone",
      "label": "联系电话"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm._f("formatPhone")(row.cellphone)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "",
      "label": "邀请人"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.invitationUserName || "-") + " ")];
      }
    }])
  }), _vm.$platSs(2) ? _c('el-table-column', {
    attrs: {
      "prop": "organName",
      "label": "自有渠道",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.organName || "-") + " ")];
      }
    }], null, false, 2996671616)
  }) : _vm._e(), _vm.$platSs(2) ? _c('el-table-column', {
    attrs: {
      "prop": "distributionType",
      "label": "用户类型"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.distributionTypeMaps[row.distributionType] || "-") + " ")];
      }
    }], null, false, 4066514651)
  }) : _vm._e(), _c('el-table-column', {
    attrs: {
      "prop": "roleType",
      "label": "机构角色"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [row.roleType ? _c('span', {
          class: {
            red: row.roleType == 2
          }
        }, [_vm._v(" " + _vm._s(row.roleType == 1 ? "经纪人" : "管理员") + " ")]) : _c('span', [_vm._v("-")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "inDate",
      "label": "注册时间"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "auditStatus",
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var _vm$auditStatusMaps$r, _vm$auditStatusMaps$r2;
        var row = _ref7.row;
        return [row.auditStatus == 1 ? _c('span', [_vm._v(" " + _vm._s(_vm.statusMaps[row.status]) + " ")]) : _c('span', {
          class: (_vm$auditStatusMaps$r = _vm.auditStatusMaps[row.auditStatus]) === null || _vm$auditStatusMaps$r === void 0 ? void 0 : _vm$auditStatusMaps$r.color
        }, [_vm._v(_vm._s(((_vm$auditStatusMaps$r2 = _vm.auditStatusMaps[row.auditStatus]) === null || _vm$auditStatusMaps$r2 === void 0 ? void 0 : _vm$auditStatusMaps$r2.label) || "-"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [row.auditStatus == 1 && row.status == 1 && row.distributionType != 2 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.admin(row);
            }
          }
        }, [_vm._v(_vm._s(row.roleType == 2 ? "取消管理员" : "设为管理员"))]) : _vm._e(), row.auditStatus == 1 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.lock(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.status == 1 ? "锁定" : "恢复") + " ")]) : _vm._e(), row.auditStatus == 0 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.audit(row.id, 2);
            }
          }
        }, [_vm._v(" 审核 ")]) : _vm._e(), row.auditStatus == 2 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.audit(row.id, 1);
            }
          }
        }, [_vm._v(" 通过 ")]) : _vm._e(), _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.del(row);
            }
          }
        }, [_c('span', {
          staticClass: "red"
        }, [_vm._v("删除")])])];
      }
    }])
  })], 1), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getList
    }
  }), _c('relational-chart', {
    attrs: {
      "title": "邀请关系图",
      "visible": _vm.relationalChartVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.relationalChartVisible = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }